.project {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.project-image-container {
  max-width: 1200px;
  margin: 20px auto;
}

.project-image {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
}

.project-image-container img {
  max-width: 800px;
  max-height: 400px;
  width: 100%;
  height: auto;
  object-fit: contain;
  transition: opacity 1s;
}

.centered-images-table {
  margin: 0 auto;
}

.project-description {
  font-size: 18px;
  margin: 20px auto;
  text-align: center;
  color: cornflowerblue;
  line-height: 1.4;
  max-width: 900px;
  padding: 10px;
  border-radius: 10px;
}

.app-button {
  background-color: #23395B;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  background-image: linear-gradient(160deg, #23395B 0%, #1a2e4a 100%);
}

.app-button:hover {
  background-color: #1a2e4a;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.25);
  transform: translateY(-2px);
}

.app-button:active {
  transform: translateY(0px);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}

.slider {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
}

.slide-container {
  display: flex;
  transition: transform 0.3s;
  transform: translateX(0%);
}

.current-slide,
.next-slide {
  min-width: 100%;
  transition: transform 0.3s;
}

.swipe-prompt {
  text-align: center;
  font-style: italic;
  margin-top: 5px;
  color: cornflowerblue;
}

img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.built-with-section {
  margin-top: 20px;
  text-align: center;
}

.logo-container {
  margin-top: 10px;
}

.logo {
  width: 50px;
  height: auto;
  margin-right: 10px;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 80%;
  max-width: 800px;
  max-height: 100%;
  height: 80%;
  overflow-y: auto;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.feedback-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.form-label {
  margin-bottom: 10px;
  width: 100%;
  color: #23395B;
  font-weight: 600;
}

.form-input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  box-sizing: border-box;
  color: #23395B;
}

.form-textarea {
  height: 100px;
  resize: none;
}

.modal-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: cornflowerblue;
}