body {
  background-color: black;
  color: white;
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  min-height: 100vh;
}

a {
  color: royalblue;
}

a:visited {
  color: cadetblue;
}

.mobile {
  background-color: black;
}

.coding-background {
  padding: 20px;
  min-height: 100vh;
}

/* Responsive styles */
@media (max-width: 768px) {
  .sections-container {
    flex-direction: column;
  }

  .section {
    min-width: unset;
  }
}

@media (max-width: 480px) {
  .portfolio {
    padding: 10px;
  }

  .skill {
    font-size: 12px;
    padding: 5px 8px;
  }
}