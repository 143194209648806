.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f9fa;
  color: #343a40;
}

.error-page h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.error-page p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.home-link {
  color: #007bff;
  text-decoration: none;
  font-size: 1.2em;
}

.home-link:hover {
  text-decoration: underline;
}