.navbar-icon {
  position: fixed;
  top: 20px;
  left: 20px;
  font-size: 24px;
  cursor: pointer;
  color: cornflowerblue;
  z-index: 2;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  height: auto;
  width: 150px;
  background-color: #1a1a1a95;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  transition: width 0.3s;
  z-index: 2;
}

.navbar-list {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.navbar-link {
  text-decoration: none;
  cursor: default;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  transition: background-color 0.3s;
}

.navbar-link:hover {
  background-color: #44444475;
}

.navbar-list > div {
  position: relative;
}

.dropdown {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
  min-width: 150px;
  background-color: #1a1a1a95;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
}

.navbar-list div:hover .dropdown {
  display: block;
}

.dropdown .navbar-link {
  padding: 10px 15px;
}

.navbar-dropdown.ios .dropdown {
  min-width: 200px;
}

.navbar-icon-img {
  width: 24px;
  height: 24px;
  margin-left: 8px;
  border-radius: 10%;
}

.navbar-container {
  position: relative;
}

.navbar-icon-container {
  display: flex;
  align-items: center;
}

.pause-animation-icon {
  position: fixed;
  top: 10px;
  right: 0px;
  font-size: 24px;
  background: none;
  border: none;
  color: slateblue;
  cursor: pointer;
  transition: color 0.3s ease;
  z-index: 1000;
}

.pause-animation-icon:hover {
  color: cornflowerblue;
}