.title {
  color: cornflowerblue;
  text-align: center;
}

.section-title {
  font-size: xx-large;
  color: slateblue;
  text-align: center;
}

.sections-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.section {
  background-color: #1a1a1a85;
  color: #6495ed;
  padding: 20px;
  border-radius: 10px;
  margin: 20px;
  text-align: center;
  z-index: 1;
}

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.rounded-images-table img {
  border-radius: 8px;
}

.skills-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0;
  justify-content: center;
  list-style: none;
}

.skill {
  background-color: #23395b85;
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
  margin: 5px;
}

.skill:hover {
  background-color: #0056b375;
  transform: scale(1.05);
  cursor: default;
}